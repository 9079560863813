
import { computed, defineComponent, PropType } from 'vue';

/** Interfaces */
import { FeesSummaryI, QuoteII } from '@/interfaces/payPlan.interface';

export default defineComponent({
  name: 'FeesTable',
  emits: [],
  props: {
    feesSummary: {
      type: Array as PropType<FeesSummaryI[]>,
      required: true,
      default: () => [],
    },
    isLince: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const getFees = computed<QuoteII[]>(() => {
      return props.feesSummary[0]?.fees || [];
    });

    return {
      getFees,
    };
  },
});
